<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
      wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
    </p>
    <br /><br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper mt-2 mb-2 mt-md-0 mb-md-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" placeholder="Search here...">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="p-relative">
        <button
          type="button"
          class="btn mulai  font-bold"
          @click="buatMarketingKit"
        >
          <fa-icon icon="plus-circle" class="mr-1" /> BUAT MARKETING KIT
        </button>
      </div>
    </div>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handlePublish(row.item.id, row.item.published==0 ? 1 : 0)"
          v-if="row.item.published==0"
        >
          <fa-icon icon="plus-circle" class="mr-1" />Publish
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handlePublish(row.item.id, row.item.published==1 ? 0 : 1)"
          v-if="row.item.published==1"
        >
          <fa-icon icon="minus-circle" class="mr-1" />Unpublish
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleEdit(row.item.id)"
        >
          <fa-icon icon="pencil-alt" class="mr-1" />Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleDelete(row.item)"
        >
          <fa-icon icon="trash-alt" class="mr-1" /> Delete
        </b-button>

        <!-- <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-if="row.item.published"
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="minus-circle" class="mr-1" /> Unpublish
        </b-button>

        <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-else
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="circle" class="mr-1" /> Publish
        </b-button> -->
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-modal
      v-model="roleModal"
      size="sm"
      title="Role"
      centered
    >
      <label>User Role</label>
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="currentRole"
        :options="role"
        name="flavour-1"
      ></b-form-checkbox-group>
   
      <template v-slot:modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="confirm"
        >
          SAVE
        </b-button>
      </template>
      <br />
      <br />
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      currentRole: [],
      currentId: null,
      isBusy: true,
      selectId: null,
      items: [], 
      fields: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      showPopup: false,
      role:[],
      roleModal:false
    };
  },

  mounted() {
    this.totalRows = this.items.length;
    this.marketingKitList();
  },

  methods: {
    ...mapActions({
      getKitList: types.GET_MARKETING_KIT_LIST,
      getRoleList: types.GET_ROLE_LIST,
      deleteKit: types.DELETE_MARKETING_KIT,
      updateUserRole: types.UPDATE_USER_ROLE,
      publishMarketing: types.PUBLISH_MARKETING_KIT
    }),
    confirm() {
      this.updateUserRole({id:this.currentId, role:this.currentRole})
      .then(response => {
        this.roleModal = false;
        this.marketingKitList();
      })
      .catch(error => {
        this.toastError('b-toaster-top-center', error);
      })
    },
    handleDelete(item) {
      this.$bvModal
      .msgBoxConfirm("Delete kit?", {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (value) {
          this.deleteKit({id:item.id})
          .then(response => {
            this.toastSuccess('b-toaster-top-center', 'Data deleted');
            this.marketingKitList();
          })
          .catch(error => {
            this.toastError('b-toaster-top-center', error);
          })
        }
      })
      .catch(err => {
        this.toastError('b-toaster-top-center', err);
      });
    },
    handlePublish(id, publish) {
      this.$bvModal
      .msgBoxConfirm(publish ? "Publish data?" : "Unpublish data?", {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (value) {
          this.publishMarketing({publish:publish, id:id})
          .then(response => {
            this.toastSuccess('b-toaster-top-center', publish ? "Data published" : "Data unpublished");
            this.marketingKitList();
          })
          .catch(error => {
            this.toastError('b-toaster-top-center', error);
          })
        }
      })
      .catch(err => {
        this.toastError('b-toaster-top-center', err);
      });
    },
    marketingKitList(level_id = null) {
      this.isBusy = true;
      this.getKitList()
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              name: res[i].name,
              type: res[i].type_name,
              published: res[i].published
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    handleEdit(id) {
      this.$router.push("/marketing-kit/edit/"+id);
    },
    toastSuccess(toaster, message, append = false) {
      this.$bvToast.toast(message, {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 1000
      })
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 1000
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    buatMarketingKit() {
      this.$router.push("/marketing-kit/add");
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}

.product-popup {
  position: absolute;
  z-index: 99;
  right: 0;
  top: -88px;
  width: 316px;
  height: 70px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f08045;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px;
  display: flex;
}

.product-popup__item {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #494949;
  flex-direction: column;
}

.product-popup__item:hover {
  background-color: #ffe1d2;
}

.product-popup-arrow {
  position: absolute;
  z-index: 999;
  right: 99px;
  top: -19px;
  width: 14px;
}

.search-wrappper {
  min-width: 160px;
}

.pub-unpub {
  min-width: 105px;
}
</style>
